<template>
<div class="assets">
    <div class="title">{{$t('trade.text108')}}</div>
    <ul>
        <li><div class="assets_line" :style="{color:(data.warning == 0?'#16BB94':data.warning == 1?'#FF8B0A':'#FB6669')}">{{data.warningTips}}</div></li>
        <li><div class="assets_title">{{$t('trade.text109')}}</div><div class="assets_value">{{data.balance || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text110')}}</div><div class="assets_value">{{data.equity || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text111')}}</div><div :class="['assets_value',dynamicProfit<=0?'down':'up']">{{dynamicProfit || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text112')}}</div><div class="assets_value">{{data.freeMargin || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text113')}}</div><div class="assets_value">{{data.margin || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text114')}}</div><div class="assets_value">{{data.credit || '- -'}}</div></li>
        <li><div class="assets_title">{{$t('trade.text115')}}</div><div class="assets_value">{{data.marginRate || '- -'}}</div></li>
    </ul>
    <div class="deposit">
        <!-- <el-button @click="gotoDeposit" type="primary">
            Deposit
        </el-button> -->
    </div>
</div>
</template>
<script>
import {
 ref, reactive, onMounted,inject, onBeforeUnmount,computed,
} from 'vue';
import apiServer from "@/api/request";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';

export default {
    name:'assets',
    components: {
    },
    setup(props) {
        const emitt = inject('emitt');
        const store = useStore();
        const router = useRouter();
        let timer = null;
        const data = reactive({
        });
        const userToken = computed({ get: () => store.getters.userToken });
        const dynamicProfit = ref(0)

        onMounted(() => {
            if(userToken.value){
                getUserBalance()
                timer = setInterval(() => {
                    getUserBalance()
                },5000);
            }
        })
        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
            emitt.$unsub("getUserBalanceDynamicProfit");

        })
        function gotoDeposit(){
            router.push({name:'payment'});
        }
        function getUserBalance(){
            let params = {
                usertoken:localStorage.userToken
            }
            apiServer.getUserBalance(params).then(res => {
                Object.assign(data,res.data)
                sessionStorage.freeMargin = res.data.freeMargin
            })
        }

         emitt.$sub('getUserBalanceDynamicProfit', (val) => {
            let params = val[0];
            dynamicProfit.value = params;
        });

       
        return {
            data,
            gotoDeposit,
            dynamicProfit
        }
    }
}
</script>
<style lang="less">
.assets{
    .title{
        font-size: 14px;/*no*/
        font-weight: bolder;
        padding: 10px 16px;/*no*/
        text-align: left;
        border-bottom: 1px solid @table-border-color;/*no*/
    }
    ul{
        padding: 0 16px;/*no*/
        margin-top: 13px;/*no*/
        li{
            display: flex;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 21px;/*no*/
            margin-bottom: 10px;/*no*/
            .assets_title{
                width: 50%;
                font-size: 12px;/*no*/
                color: @text-color-secondary;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .assets_value{
                width: 50%;/*no*/
                font-size: 12px;/*no*/
                text-align: right;
                color: @text-color;
            }
            .assets_line{
                width: 100%;
                font-size: 14px;/*no*/
                font-weight: bold;
            }
        }
    }
    .deposit{
        padding: 0 16px;/*no*/
        margin-top: 31px;/*no*/
        .el-button{
            width: 100%;/*no*/
        }
    }
}
</style>