<template>
  <div class="closedPosition">
    <div class="table_box">
      <el-table
        v-loading="tloading"
        :data="dataPositionClose"
        size="small"
        :empty-text="$t('trade.text66')"
      >
        <el-table-column
          fixed="left"
          prop="symbol"
          :label="$t('trade.text50')"
        />
        <el-table-column prop="bsType" :label="$t('trade.text51')">
          <template #default="scope">
            <span :class="scope.row.bsType == 1 ? 'up' : 'down'">{{
              scope.row.bsType == 1 ? $t("trade.text30") : $t("trade.text32")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quantity"
          :label="$t('trade.text52')"
          width="50"
        />
        <el-table-column
          prop="profit"
          :label="$t('trade.text76')"
          min-width="90"
        >
          <template #default="scope">
            <span :class="scope.row.profit >= 0 ? 'up' : 'down'">{{
              scope.row.profit
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="openPrice" :label="$t('trade.text53')" />
        <el-table-column
          prop="closePrice"
          :label="$t('trade.text77')"
          min-width="95"
        />
        <el-table-column prop="swap" :label="$t('trade.text79')" width="90" />
        <el-table-column
          prop="stopProfit"
          min-width="100"
          :label="$t('trade.text58')"
        />
        <el-table-column
          prop="stopLoss"
          :label="$t('trade.text59')"
          min-width="100"
        />
        <el-table-column
          align="center"
          prop="openOrderNo"
          min-width="140"
          :label="$t('trade.text61')"
        />
        <el-table-column
          prop="openTime"
          :label="$t('trade.text60')"
          min-width="130"
        />
        <el-table-column
          prop="closeTime"
          :label="$t('trade.text134')"
          min-width="130"
        />
        <el-table-column
          prop="closeType"
          :label="$t('trade.text62')"
          min-width="120"
        />
        <template #empty>
          <div class="empty" v-if="userToken">
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_no_data%402x.png"
              alt=""
            />
            <p>{{ $t("trade.text66") }}</p>
          </div>
          <div v-else class="no_login">
            <img
              src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_login%402x.png"
              alt=""
            />
            <el-button @click="goLogin" class="login_btn" type="primary">{{
              $t("trade.text67")
            }}</el-button>
          </div>
        </template>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :page-size="9"
        layout="prev, pager, next"
        :total="totalPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { ref, reactive, inject, onBeforeUnmount, computed } from "vue";
import apiServer from "@/api/request";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "closedPosition",
  setup() {
    const tloading = ref(false);
    const dataPositionClose = ref([]);
    const nowPage = ref(1); //当前页
    const totalPage = ref(1);
    const emitt = inject("emitt");
    const store = useStore();
    const userToken = computed({ get: () => store.getters.userToken });
    const router = useRouter();

    let searchObj = {
      symbol: "",
      searchStartDate: "",
      searchEndDate: "",
    };
    // closeTrade();

    //点击查询
    emitt.$sub("searchClosePosition", (val) => {
      let params = val[0];
      searchObj.symbol = params.findSymbol;
      searchObj.searchEndDate = params.endDate;
      searchObj.searchStartDate = params.beginDate;
      totalPage.value = 0;
      nowPage.value = 1;
      closeTrade();
    });
    onBeforeUnmount(() => {
      emitt.$unsub("searchClosePosition");
    });
    //查询已平仓
    function closeTrade() {
      const params = {
        symbol: searchObj.symbol,
        startdate: searchObj.searchStartDate,
        enddate: searchObj.searchEndDate,
        pageSize: 9,
        page: nowPage.value,
      };
      tloading.value = true;
      apiServer.closeTrade(params).then((res) => {
        if (res.status == 1) {
          dataPositionClose.value = res.data.list;
          totalPage.value = Number(res.data.totalCount);
        }
        tloading.value = false;
      });
    }

    //切换页面
    function handleCurrentChange(e) {
      console.log(e);
      nowPage.value = e;
      closeTrade();
    }
    function goLogin() {
      router.push("/signUp");
    }
    return {
      tloading,
      dataPositionClose,
      handleCurrentChange,
      totalPage,
      userToken,
      goLogin,
    };
  },
};
</script>
<style lang="less" scoped>
.closedPosition {
  .table_box {
    padding: 0 20px;
    margin-top: 16px;
  }
  .no_login {
    margin-top: 100px;
    margin-bottom: 50px;
    img {
      display: block;
      margin: 0 auto;
    }
    .login_btn {
      margin-top: 24px;
      width: 168px;
    }
  }
  .pagination {
    margin-top: 24px;
    float: right;
  }
}
</style>
<style lang="less">
</style>
