<template>
<div class="orderList">
    <div class="order_list_title">
        <div class="order_tabs">
            <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_account%402x.png" alt="">
            <div @click="activeAccount = 'live'" class="account live_account cursor" :class="activeAccount == 'live'?'active_account':''">{{$t('trade.text46')}}</div>
            <!-- <div @click="activeAccount = 'follow'" class="account cursor" :class="activeAccount == 'follow'?'active_account':''">Follow account</div> -->
            <div class="closedMarketText" v-if="closedMarketText" v-html="closedMarketText"></div>
        </div>
        <div class="live_account_data">
            <div class="account_type cursor" @click="changeDataType('positions')" :class="dataType=='positions'?'active_data':''">
                {{$t('trade.text47')}}
            </div>
            <div class="account_type cursor" @click="changeDataType('pendingOrder')" :class="dataType=='pendingOrder'?'active_data':''">
                {{$t('trade.text48')}}
            </div>
            <div class="account_type cursor" @click="changeOrderType('closed')" :class="dataType == 'closed'?'active_data':''">
                {{$t('AccountAssets.text24')}}
            </div>
            <div class="account_type cursor" @click="changeOrderType('cancelled')" :class="dataType == 'cancelled'?'active_data':''">
                {{$t('AccountAssets.text25')}}
            </div>
        </div>
        <div class="table_data">
           <position-data :tloading="tloading" v-show="dataType=='positions'" :dataPosition='dataPosition'></position-data>
        </div>
        <div class="table_data">
            <on-the-list :tloading="tloading"  v-show="dataType=='pendingOrder'" :dataPosition='dataPositionOn'></on-the-list>
        </div>
        <div class="statistics" v-if="dataType == 'closed'">
            <div class="total">
                <div class="total_data">
                    <div class="title">{{$t('AccountAssets.text10')}}：</div>
                    <div class="total_value" :class="tradeStats.totalProfit<=0?'down':'up'">{{tradeStats.totalProfit}}</div>
                </div>
                <div class="total_data" :class="tradeStats.totalSwap<=0?'down':'up'">
                    <div class="title">{{$t('AccountAssets.text21')}}：</div>
                    <div class="total_value">{{tradeStats.totalSwap}}</div>
                </div>
                <div class="total_data">
                    <div class="title">{{$t('AccountAssets.text22')}}：</div>
                    <div class="total_value">{{tradeStats.totalLots}}</div>
                </div>
                <div class="total_data">
                    <div class="title">{{$t('AccountAssets.text23')}}：</div>
                    <div class="total_value">{{tradeStats.totalCount}}</div>
                </div>
            </div>
        </div>
        <div class="search" v-if="dataType == 'closed' || dataType == 'cancelled'">
            <div class="search_symbol">
                <span class="search_title">{{$t('AccountAssets.text14')}}</span>
                <el-select v-model="findSymbol" class="m-2" >
                    <el-option
                    v-for="item in productList"
                    :key="item.symbol"
                    :label="item.symbol"
                    :value="item.symbol"
                    />
                </el-select>
            </div>
            <div class="search_date">
                <span class="search_title">
                    {{$t('AccountAssets.text15')}}
                </span>
                <div class="screening_picker">
                      <el-date-picker
                            v-model="searchDate"
                            type="daterange"
                            :start-placeholder="$t('AccountAssets.text17')"
                            :end-placeholder="$t('AccountAssets.text18')"
                            format="MM/DD/YYYY"
                            value-format="YYYY-MM-DD"
                        />
                </div>
            </div>
            <el-button type="primary" @click="search" class="search_btn">{{$t('AccountAssets.text19')}}</el-button>
        </div>
        <div class="table_data">
            <ClosedPosition v-show="dataType == 'closed'"/>
        </div>
        <div class="table_data"> 
            <BillCancelled v-show="dataType == 'cancelled'"/>
        </div>
    </div>
</div>
</template>
<script>
import {
 ref, reactive, onBeforeUnmount,inject,computed,onMounted
} from 'vue';
import apiServer from "@/api/request";
import { useStore } from 'vuex'
import positionData from './orderList/positionData'
import onTheList from './orderList/onTheList'
import { toRaw } from '@vue/reactivity'
import ClosedPosition from './orderList/ClosedPosition'
import BillCancelled from './orderList/billCancelled'
import { useI18n } from "vue-i18n";

export default {
    name:'orderList',
    components: {
       positionData,
       onTheList,
       BillCancelled,
        ClosedPosition,
    },
    setup(props) {
        const emitt = inject('emitt');
        let timer = null;
        const { t } = useI18n()
        const dataPosition = ref([]);
        const dataPositionOn = ref([]);
        const store = useStore();
        const empty = ref(false);          
        const activeAccount = ref('live')
        const dataType = ref('positions');
        const tloading = ref(false);
        const userToken = computed({ get: () => store.getters.userToken });
        const searchDate = ref('');
        const findSymbol = ref(t('AccountAssets.text16'));
        const productList = ref([]);

        const tradeStats = reactive({
            totalProfit: "",     //总平仓盈亏
            totalSwap:"",        //总隔夜利息
            totalCount: "",      //总平仓订单数
            totalLots: ""         
        })
        onMounted(() => {
            if(userToken.value){
                let params = {
                    isLiveOrDemo:0
                };
                apiServer.His(params).then((res) => {
                    let list = res.data.list;
                    for(let item of list){
                        productList.value.push.apply(productList.value,item.list)
                    }
                    productList.value = [{symbol:t('AccountAssets.text16')}].concat(productList.value)
                })
            }
       })
      if(store.getters.userToken){
          queryPosition('first');
         timer = setInterval(() => {
            queryPosition();
        },3000);
      }
        let positionLength = 0;
       
        //查询已持仓
        function queryPosition(e){
            if(e == 'first'){
                tloading.value = true;
            }
            apiServer.queryPosition().then((res) => {
                if(res.status == 1){
                    dataPosition.value = res.data.posList;

                    let dynamicProfit = 0;

                    for(let item of res.data.posList){
                        dynamicProfit += (Number(item.profit)+Number(item.swap))
                    }
                    
                    emitt.$pub('getUserBalanceDynamicProfit',dynamicProfit.toFixed(2));
                    
                }
                if(dataPosition.value.length == 0){
                    empty.value = true
                }else{
                    empty.value = false
                }
                if(e == 'first'){
                    tloading.value = false;
                    positionLength = res.data.posList.length;
                }else{
                    if(positionLength != res.data.posList.length){
                        positionLength = res.data.posList.length
                        emitt.$pub('showStopProfitAndStopLoss',false);
                        emitt.$pub('closehandCount');
                    }
                }
            })
        }
        GetOpenAccountActivity();
        let mytimer = null;
        mytimer = setInterval(GetOpenAccountActivity,30000);
        onBeforeUnmount(() => {
            clearInterval(mytimer);
            mytimer == null;
            clearInterval(timer)
            timer = null;
        })
        const closedMarketText = ref('');
        function GetOpenAccountActivity(){
            apiServer.GetOpenAccountActivity().then(res => {
                if(res.data && res.data.closedMarketText){
                    closedMarketText.value = res.data.closedMarketText.replace("<br>",'');
                }else{
                    closedMarketText.value = ""
                    mytimer == null;
                    clearInterval(mytimer);
                }
            })
        }

        let dataPositionOnLength = 0;
        //查询已挂单
        function queryOrder(e){
            if(e == 'first'){
                tloading.value = true;
            }
            apiServer.queryOrder().then((res) => {
                if(res.status == 1){
                    dataPositionOn.value = res.data.orderList;
                }
                if(dataPositionOn.value.length == 0){
                    empty.value = true
                }else{
                     empty.value = false
                }
                 if(e == 'first'){
                    tloading.value = false;
                    dataPositionOnLength = res.data.orderList.length;
                }else{
                    if(dataPositionOnLength != res.data.orderList.length){
                        dataPositionOnLength = res.data.orderList.length
                        emitt.$pub('showModifyOrder',false);
                    }
                }
            })
        }

        //切换持仓/挂单
        function changeDataType(e){
            if(!userToken.value) {
                dataType.value = e;
                return;
            }

            dataType.value = e;
            empty.value = false;
            clearInterval(timer)
            timer = null;
            tloading.value = true;
            if(e == 'positions'){
                queryPosition('first');
                timer = setInterval(() => {
                    queryPosition();
                },3000);
            }else if(e == 'pendingOrder'){
                queryOrder('first');
                timer = setInterval(() => {
                    queryOrder();
                },3000);
            }
        }
        
        //切换订单类型
        function changeOrderType(e){
            if(!userToken.value) {
                dataType.value = e;
                return;
            }
            clearInterval(mytimer);
            mytimer == null;
            clearInterval(timer)
            timer = null;
            dataType.value = e;
            findSymbol.value = t('AccountAssets.text16');

            search();

        }


        /*
         * 查询-开始
         */
        function search(){
            let params = {
                beginDate:searchDate.value?searchDate.value[0]:'',
                endDate:searchDate.value?searchDate.value[1]:'',
                findSymbol:findSymbol.value == t('AccountAssets.text16')?'':findSymbol.value
            }
            closeTradeStats();
            if(dataType.value == 'closed'){
                emitt.$pub('searchClosePosition',params);
            }else{
                emitt.$pub('searchBillPosition',params);
            }
        }
        /**
         * 查询-结束
         */


        //获取已平仓订单的汇总信息
        function closeTradeStats(){
            let params = {
                usertoken:localStorage.userToken,
                startDate:searchDate.value?searchDate.value[0]:'',
                endDate:searchDate.value?searchDate.value[1]:'',
                symbol:findSymbol.value == t('AccountAssets.text16')?'':findSymbol.value
            }
            apiServer.closeTradeStats(params).then(res => {
                Object.assign(tradeStats,res.data)
            })
        }
        return {
            dataPosition,
            empty,
            queryOrder,
            dataPositionOn,
            activeAccount,
            dataType,
            changeDataType,
            closedMarketText,
            tloading,
            tradeStats,
            changeOrderType,
            searchDate,
            findSymbol,
            productList,
            search
        }
    }
}
</script>
<style lang="less" scoped>
.orderList{
    height: 100%;
    position: relative;
   .order_list_title{
       .order_tabs{
           height: 40px;/*no*/
           padding-left: 16px;
           border-bottom:1px solid @border-color-base;/*no*/
           padding-right: 8px;
           img{
               display: block;
               width: 16px;/*no*/
               height: 16px;/*no*/
               margin-top: 12px;/*no*/
               float: left;
           }
           .account{
                height: 40px;/*no*/
                line-height: 40px;/*no*/
                position: relative;
                float: left;
           }
           .closedMarketText{
                text-align: center;
                height: 40px;/*no*/
                line-height: 40px;/*no*/
                color: @warning-color;
                font-size: 12px;/*no*/
                float: right;
            }
           .active_account{
                color: @primary-color;
                font-weight: 500;
                &::before{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;/*no*/
                    background: @primary-color;
                    position: absolute;
                    bottom: 0px;/*no*/
                }
           }
            .live_account{
                margin-left: 10px;
                margin-right: 51px;
            }
       }
       .live_account_data{
           height: 44px;/*no*/
           padding:10px 16px;/*no*/
           .account_type{
               float: left;
                padding: 3px 10px 4px 11px ;/*no*/ 
                border: 1px solid @border-color-base;/*no*/
                border-radius: 2px;/*no*/
                height: 24px;/*no*/
                font-size: 12px;
                color: @text-color-secondary;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
           }
           .active_data{
                border: 1px solid @primary-color;/*no*/
                color: @primary-color;
           }
       }
   }
     .search{
        display: flex;
        justify-content: left;
        padding: 16px 16px 0 16px;
        .search_symbol{
            margin-right: 28px;
            display: flex;
            width: 300px;
            align-items: center;
        }
        .search_title{
            margin-right: 10px;
            font-size: 14px;
            color: @text-color-secondary;
            line-height: 40px;
            flex-shrink: 0;
        }
        .search_date{
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
        }
        .screening_picker{
            width: 390px;
            padding-top: 5px;
        }
        .hr{
            color:@text-color-secondary;
            line-height: 40px;
            margin: 0 8px;
            font-size: 14px;
        }
        .search_btn{
            width: 80px;
            margin-top: 5px;
        }
    }
    .statistics{
        padding: 10px 16px;
        background: #F7F8FA;
        .total{
            display: flex;
            justify-content: left;
            .total_data{
                width: 220px;
                background: @bg-color;
                border-radius: 4px;
                display: flex;
                justify-content: left;
                font-size: 14px;
            }
            .total_value{
                font-weight: 500;
            }
            .title{
                color: @text-color-secondary;
            }
        }
    }
   .empty{
       text-align: center;
       padding-top: 50px;
       color: @text-color-third;
   }
   .page{
       position: absolute;
       right: 10px;
       bottom: 3px;
   }
}
</style>