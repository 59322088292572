<template>
  <div class="transaction">
    <div class="transaction_top">
      <div class="transaction_top_symbol" v-show="left_flag">
        <tran-symbol></tran-symbol>
      </div>
      <div
        class="transaction_top_kline"
        :class="
          middle_flag == 1
            ? ''
            : middle_flag == 2
            ? 'transaction_top_kline_2'
            : middle_flag == 3
            ? 'transaction_top_kline_3'
            : ''
        "
      >
        <div class="open_right" @click="openRight">
          <img :src="right_src" alt="" />
        </div>
        <div class="open_left" @click="openLeft">
          <img :src="left_src" alt="" />
        </div>
        <k-line></k-line>
      </div>
      <div class="transaction_top_right" :class="right_flag?'':'transaction_top_right_hidden'">
        <offer-analysis-rule></offer-analysis-rule>
      </div>
    </div>
    <div class="transaction_bottom">
      <div class="transaction_bottom_assets" v-show="left_flag">
        <assets></assets>
      </div>
      <div
        class="transaction_bottom_warehouse"
        :class="left_flag ? '' : 'transaction_bottom_warehouse_hidden'"
      >
        <order-list></order-list>
      </div>
    </div>
  </div>
</template>

<script>
import tranSymbol from "./composition/TranSymbol";
import KLine from "./composition/KLine2";
import offerAnalysisRule from "./composition/offerAnalysisRule";
import orderList from "./composition/orderList";
import assets from "./composition/assets";
import { useI18n } from "vue-i18n";
import { inject, ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "transaction",
  components: {
    tranSymbol,
    KLine,
    offerAnalysisRule,
    orderList,
    assets,
  },
  setup() {
    const { t } = useI18n();
    const emitt = inject("emitt");

    const right_src_open = t("trade.text152");
    const right_src_close = t("trade.text153");

    const left_src_open = t("trade.text154");
    const left_src_close = t("trade.text155");

    const right_src = ref(right_src_close);
    const left_src = ref(left_src_close);

    const left_flag = ref(true);
    const middle_flag = ref(1); //中间交易的页面状态
    const right_flag = ref(true);

    function openRight() {
      console.log("1111");
      right_flag.value = !right_flag.value;
      if (right_flag.value) {
        if (left_flag.value) {
          middle_flag.value = 1;
        } else {
          middle_flag.value = 2;
        }
        right_src.value = right_src_close;
      } else {
        if (left_flag.value) {
          middle_flag.value = 2;
        } else {
          middle_flag.value = 3;
        }
        right_src.value = right_src_open;
      }
    }

    function openLeft() {
      left_flag.value = !left_flag.value;
      if (left_flag.value) {
        if (right_flag.value) {
          middle_flag.value = 1;
        } else {
          middle_flag.value = 2;
        }
        left_src.value = left_src_close;
      } else {
        if (right_flag.value) {
          middle_flag.value = 2;
        } else {
          middle_flag.value = 3;
        }
        left_src.value = left_src_open;
      }
    }

    function middleFlagFunc() {
      if (right_flag.value) {
        if (left_flag.value) {
          middle_flag.value = 1;
        } else {
          middle_flag.value = 2;
        }
      } else {
        if (left_flag.value) {
          middle_flag.value = 2;
        } else {
          middle_flag.value = 3;
        }
      }
    }
    handleResize();
    function handleResize() {
      if (window.innerWidth < 1300) {
        left_flag.value = false;
        middleFlagFunc();
      }
      if (window.innerWidth < 1100) {
        right_flag.value = false;
        middleFlagFunc();
      }
    }
    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
      window.onresize = function () {
        setRem();
      };
    });

    const baseSize = 16;

    // 设置 rem 函数
    function setRem() {
      let val;
      let clientWidth = document.documentElement.clientWidth;
      // 判断浏览器函数
      if (isMobile()) {
        val = 750; // 移动端
      } else {
        val = 1920;
        // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
        if (clientWidth < 1240) {
          clientWidth = 1240;
        }
      }

      const scale = clientWidth / val;
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 1) + "px";
    }
    return {
      openRight,
      right_src,
      left_src,
      openLeft,
      left_flag,
      middle_flag,
      right_flag,
    };
  },
};
</script>
<style lang="less">
.transaction {
  height: 1160px;

  /*no*/
  .transaction_top {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .transaction_top_symbol {
      flex-shrink: 0;
      width: 310px;
      /*no*/
      height: 568px;
      /*no*/
      background: @white;
    }

    .transaction_top_kline {
      width: calc(100% - 630px); /*no*/
      margin: 0 10px; /*no*/
      height: 568px;
      /*no*/
      background: @white;
      position: relative;
      z-index: 9;
      .open_right {
        position: absolute;
        right: -15px;
        top: 70px;
        cursor: pointer;
      }
      .open_left {
        position: absolute;
        left: -15px;
        top: 70px;
        cursor: pointer;
      }
    }

    .transaction_top_right {
      flex-shrink: 0;

      width: 300px;
      /*no*/
      height: 568px;
      /*no*/
      background: @white;
      display: block;
    }
  }

  .transaction_bottom {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .transaction_bottom_warehouse {
      width: calc(100% - 320px);
      /*no*/
      height: 576px;
      /*no*/
      background: @white;
    }

    .transaction_bottom_assets {
      flex-shrink: 0;

      width: 310px;
      /*no*/
      height: 576px;
      /*no*/
      background: @white;
    }
  }
}

.transaction_top_kline_2 {
  width: calc(100% - 320px) !important; /*no*/
}

.transaction_top_kline_3 {
  width: 100% !important; /*no*/
}

.transaction_bottom_warehouse_hidden {
  margin: 0 10px; /*no*/
  width: calc(100% - 20px) !important;
}

.transaction_top_right_hidden{
  position: absolute;
  top: 9999px;
  right: 9999px;
}
</style>
