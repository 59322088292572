<template>
  <div class="ModifyOrder">
     <el-dialog v-model="visibleFlag"  width='500px' :title="$t('trade.text93')" @closed="cancelFun" destroy-on-close :close-on-click-modal="false">
           <!-- <a-spin :spinning="spinning"> -->
           <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text3')}}:</div>
                <div class="business_form_item_value">
                      {{activeItem.symbol}}
                      <img class="lable lable_red" :src="activeItem.bsType == '1'?$t('trade.text94'):$t('trade.text95')" />
                      <img class="lable lable_green" :src="activeItem.bsType == '1'?$t('trade.text94_green'):$t('trade.text95_green')" />
                      <img class="lable lable_blue" :src="activeItem.bsType == '1'?$t('trade.text94_blue'):$t('trade.text95_blue')" />
                </div>
            </div>
            <div class="business_form_item">
                    <div class="business_form_item_lable">{{$t('trade.text87')}}:</div>
                    <div class="business_form_item_value">
                            <div>
                                <el-input @input="listPriceInput" v-model="listProfit">
                                <template #suffix>
                                    <div class="fuhao jian cursor" @click="reduce('listProfit')">-</div>
                                    <div class="hr"></div>
                                    <div  class="fuhao cursor" @click="add('listProfit')">+</div>
                                </template>
                                </el-input>
                            </div>
                            <div class="business_form_item_value_bottom">{{$t('trade.text96')}}&le;{{listPriceMin}}{{$t('trade.text97')}}&ge;{{listPriceMax}}</div>

                    </div>
                </div>
            <div class="business_form_item">
                    <div class="business_form_item_lable">{{$t('trade.text58')}}:</div>
                    <div class="business_form_item_value">
                            <div>
                                <el-input @input="stopProfitInput"   v-model="stopProfit">
                                <template #suffix>
                                <div class="fuhao jian cursor" @click="reduce('stopProfit')">-</div>
                                <div class="hr"></div>
                                <div  class="fuhao cursor" @click="add('stopProfit')">+</div>
                                </template>
                                </el-input>
                            </div>
                            <div class="business_form_item_value_bottom">
                                <div>{{$t('trade.text96')}}{{activeItem.bsType == '1'?'≥':'≤'}}{{stopProfitEdge}}</div>
                                <div v-show="estimatedProfit">{{$t('trade.text98')}} ${{estimatedProfit}}</div>
                            </div>
                    </div>
                </div>
            <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text59')}}:</div>
                <div class="business_form_item_value">
                    <div>
                        <el-input @input="stopLossInput" v-model="stopLoss">
                        <template #suffix>
                            <div class="fuhao jian cursor" @click="reduce('stopLoss')">-</div>
                            <div class="hr"></div>
                            <div  class="fuhao cursor" @click="add('stopLoss')">+</div>
                        </template>
                        </el-input>
                    </div>
                    <div class="business_form_item_value_bottom">
                        <div>{{$t('trade.text96')}}{{activeItem.bsType == '1'?'≤':'≥'}}{{stopLosstEdge}}</div>
                        <div v-show="estimatedLoss">{{$t('trade.text99')}} ${{estimatedLoss}}</div>
                    </div>
                </div>
            </div>
            <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text100')}}:</div>
                <div class="business_form_item_value">
                    <el-date-picker value-format="YYYYMMDDHHmmss" :disabledDate="setFulltime"  :placeholder="$t('trade.text104')" v-model="time" size='default'  type="datetime">
                    </el-date-picker>
                </div>
            </div>
                <template #footer>
                    <span class="confirm">
                    <el-button @click="visibleFlag = false">{{$t('trade.text72')}}</el-button>
                    <el-button type="primary" :loading='loading' @click="confirm">{{$t('trade.text73')}}</el-button>
                    </span>
                </template>
           <!-- </a-spin> -->
        </el-dialog>
  </div>
</template>

<script>
import {
 ref, reactive, inject,getCurrentInstance, computed,nextTick,onBeforeUnmount
} from 'vue';
import { useStore } from 'vuex';
import apiServer from '@/api/request';
import { ElMessageBox, ElMessage } from 'element-plus'
import moment from 'moment';
import { useI18n } from "vue-i18n";

export default {
    name:"ModifyOrder",
    props:{
        activeItem:{

        },
    },
     setup(props) {
        const {ctx} = getCurrentInstance();
        const { t } = useI18n()
        const emitt = inject('emitt');
        const visibleFlag = ref(false);
        const spinning = ref(false);
        const stopProfit = ref("");
        const stopLoss = ref("");
        const listProfit = ref("");
        const time = ref("");
        let hqFloat = '';
        let marginRate = '';//杠杆比例
        let minDis = '';
        const loading = ref(false);
       
        //计算挂单价格范围
        const listPriceMin = computed({ get: () => {
           return printFn(Number(props.activeItem.marketPrice) - Number(minDis));
        } });
        //计算挂单价格范围
        const listPriceMax = computed({ get: () => {
           return printFn(Number(props.activeItem.marketPrice) + Number(minDis));
        } });
        //计算止盈范围
        const stopProfitEdge = computed({ get: () => {
            if (props.activeItem.bsType == '1') {
                    // 如果是买，止盈价格 >= 当前卖价+mindis； 止损价格<= 当前卖价-mindis
                        return printFn(Number(listProfit.value) + Number(minDis));
                }else {
                    // 如果是卖，止盈价格 <= 当前买价-mindis； 止损价格 >= 当前买价+mindis
                        return printFn(Number(listProfit.value) - Number(minDis));
                }
        } });
        //计算止损范围
        const stopLosstEdge = computed({ get: () => {
            if (props.activeItem.bsType == '1') {
                // 如果是买，止盈价格 >= 当前卖价+mindis； 止损价格<= 当前卖价-mindis
                    return printFn(Number(listProfit.value) - Number(minDis));
                }else {
                // 如果是卖，止盈价格 <= 当前买价-mindis； 止损价格 >= 当前买价+mindis
                    return printFn(Number(listProfit.value) + Number(minDis));
                }
        } });

        //计算预估盈利
        const  estimatedProfit = computed({ get: () => {
            if(props.activeItem.bsType == '1'){
                    if(stopProfit.value != '' && stopProfit.value != '--'){
                        //预计收益=保证金/杠杆比例*（止盈价-买价）/买价  （保留两位小数点
                        return (props.activeItem.margin/marginRate*(Number(stopProfit.value) - Number(listProfit.value))/Number(listProfit.value)).toFixed(2);  
                    }
                }else{
                    if(stopProfit.value != '' &&  stopProfit.value != '--'){
                        //预计收益=保证金/杠杆比例*（卖价-止盈价）/卖价   （保留两位小数点）
                        return (props.activeItem.margin/marginRate*( Number(listProfit.value) - Number(stopProfit.value))/Number(props.activeItem.marketPrice)).toFixed(2);
                    }
                }
        } });
        //计算预估亏损
        const  estimatedLoss = computed({ get: () => {
            if(props.activeItem.bsType == '1'){
                    if(stopLoss.value != '' && stopLoss.value != '--'){
                         //可能亏损=保证金/杠杆比例*（止损价-买价）/买价   （保留两位小数点）
                       return (props.activeItem.margin/marginRate*(Number(stopLoss.value) -  Number(listProfit.value))/ Number(listProfit.value)).toFixed(2);
                    }
                }else{
                    if(stopLoss.value != '' && stopLoss.value != '--'){
                        //可能亏损=保证金/杠杆比例*（卖价-止损价）/卖价     （保留两位小数点）
                       return (props.activeItem.margin/marginRate*( Number(listProfit.value) - Number(stopLoss.value))/ Number(props.activeItem.marketPrice)).toFixed(2);
                    }
                }
        } });
        emitt.$sub('showModifyOrder', (val) => {
            nextTick(() => {
                 if(val[0] != false){
                    visibleFlag.value = true;
                    buyPage();
                    spinning.value = true;
                    listProfit.value =props.activeItem.orderPrice;
                }else{
                    visibleFlag.value = false;
                }
            })
        });
       onBeforeUnmount(() => {
            emitt.$unsub("showModifyOrder");
          });
        function buyPage(){
                const params = {
                    symbol: props.activeItem.symbol,
                };
                apiServer.buyPage(params).then((res) => {
                if (res.status == 1) {
                    marginRate = res.data.marginRate;
                    stopProfit.value = props.activeItem.stopProfit == '--' ? '':props.activeItem.stopProfit;;
                    stopLoss.value = props.activeItem.stopLoss == '--' ? '':props.activeItem.stopLoss;;
                    time.value = props.activeItem.cancelTime == '--'?'':moment('20'+props.activeItem.cancelTime).format('YYYYMMDDHHmmss');
                    hqFloat = res.data.hqFloat;
                    minDis = res.data.minDis;
                    spinning.value = false;
                }
            });
        }
        function cancelFun(){
        }
        function listPriceInput(v){
            let val = v
            let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
            if(val[val.length-1] != '.'){
                    if(!regu.test(val)){
                        val = val.substring(0,val.length-1)
                    }
            }else{
                if(val.indexOf('.') != val.lastIndexOf('.')){
                    val = val.substring(0,val.length-1)
                }
            }
            
            if(val.indexOf('.') != -1){
                if(val.toString().split(".")[1].length>props.activeItem.decimalPlace){
                    val = Number(val).toFixed(props.activeItem.decimalPlace)
                }
            }
            
            nextTick(() => {
                listProfit.value = val;
            })
        }
        

         //止盈校验
        function stopProfitInput(v){
             let val = v
           let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
           if(val[val.length-1] != '.'){
                if(!regu.test(val)){
                    val = val.substring(0,val.length-1)
                }
           }else{
               if(val.indexOf('.') != val.lastIndexOf('.')){
                   val = val.substring(0,val.length-1)
               }
           }
            
            if(val.indexOf('.') != -1){
                 if(val.toString().split(".")[1].length>props.activeItem.decimalPlace){
                    val = Number(val).toFixed(props.activeItem.decimalPlace)
                }
            }
            nextTick(() => {
                stopProfit.value = val;
            })
        }
        //止损校验
        function stopLossInput(v){
            let val = v
            let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
            if(val[val.length-1] != '.'){
                    if(!regu.test(val)){
                        val = val.substring(0,val.length-1)
                    }
            }else{
                if(val.indexOf('.') != val.lastIndexOf('.')){
                    val = val.substring(0,val.length-1)
                }
            }
                
                if(val.indexOf('.') != -1){
                    if(val.toString().split(".")[1].length>props.activeItem.decimalPlace){
                        val = Number(val).toFixed(props.activeItem.decimalPlace)
                    }
                }
                nextTick(() => {
                    stopLoss.value = val;
                })
        }
         //确认
         function confirm(){
             if(Number(listProfit.value) > Number(listPriceMin.value) && Number(listProfit.value) < Number(listPriceMax.value)){
                        ElMessage.error(`${t('trade.text96')}<=${listPriceMin.value}${t('trade.text97')}>=${listPriceMax.value}`);
                        return
             }
             if(listProfit.value == ''){
                        ElMessage.error(t('trade.text101'));
                        return
             }

              if (props.activeItem.bsType == '1') {
                    // 如果是买
                    // Price{{activeItem.bsType == '1'?'≤':'≥'}}{{stopLosstEdge}}
                    if(stopLoss.value != '' && Number(stopLoss.value) > Number(stopLosstEdge.value)){
                        ElMessage.error(`${t('trade.text102')} <= `+stopLosstEdge.value);
                        return 
                    }
                    if(stopProfit.value != '' && Number(stopProfit.value) < Number(stopProfitEdge.value)){
                        ElMessage.error(`${t('trade.text103')} >= `+stopProfitEdge.value);
                        return 
                    }
                    
                }else {
                    // 如果是卖
                    if(stopLoss.value != '' && Number(stopLoss.value) < Number(stopLosstEdge.value)){
                        ElMessage.error(`${t('trade.text102')} >= `+stopLosstEdge.value);
                        return 
                    }
                    if(stopProfit.value != '' && Number(stopProfit.value) > Number(stopProfitEdge.value)){
                        ElMessage.error(`${t('trade.text103')} <= ` + stopProfitEdge.value);
                        return 
                    }
                }





             if(listProfit.value>listPriceMin.value && listProfit.value < listPriceMax.value){
                 ElMessage({
                    message: t('trade.text105'),
                    type: 'error',
                });
                 return
             }

             if(parseInt(moment().format('YYYYMMDDhhmmss')) > parseInt(time.value)){
                 ElMessage({
                    message: t('trade.text106'),
                    type: 'error',
                });
                return    
            }
             let params = {
                usertoken:localStorage.userToken,
                orderNo:props.activeItem.orderNo,
                marketPrice:props.activeItem.marketPrice,
                orderPrice:listProfit.value,
                takeProfit:stopProfit.value == '' ? '--':stopProfit.value,
                stopLoss:stopLoss.value == '' ? '--':stopLoss.value,
                cancelTime:time.value
             }
             console.log(params)
             loading.value = true;
             apiServer.modifyPendingOrder(params).then(res => {
                 if(res.status == 1){
                     visibleFlag.value = false;
                     ElMessage({
                            message: t('trade.text86'),
                            type: 'success',
                        });
                     cancelFun();
                 }else{
                      ElMessage({
                            message: res.message,
                            type: 'error',
                        });
                 }
                 loading.value = false;
             })
         }
         //减
         function reduce(e){
              switch (e){
                case 'stopProfit': 
                    if(stopProfit.value == '' || stopProfit.value == '--'){
                        stopProfit.value = stopProfitEdge.value
                    }
                    if(props.activeItem.bsType == '1'){
                         if( printFn(Number(stopProfit.value) - Number(hqFloat)) < Number(stopProfitEdge.value)) {
                            stopProfit.value  = stopProfitEdge.value;
                            return;
                        };
                    }else{
                        if( printFn(Number(stopProfit.value) - Number(hqFloat)) < 0)return;
                    }
                    stopProfit.value = printFn(Number(stopProfit.value) - Number(hqFloat));
                break;
                 case 'listProfit': 
                    if(listProfit.value == '' || listProfit.value == '--'){
                        listProfit.value = hqFloat;
                    }else{
                        if(Number(listProfit.value <=0 ))return;
                        listProfit.value = printFn(Number(listProfit.value) - Number(hqFloat));
                    }
                break;
                case 'stopLoss': 
                      if(stopLoss.value  == '' || stopLoss.value  == '--'){
                        stopLoss.value  = stopLosstEdge.value;
                    }
                    if(props.activeItem.bsType == '1'){
                        if( printFn(Number(stopLoss.value ) - Number(hqFloat)) <=0) return;
                    }else{
                         if( printFn(Number(stopLoss.value ) - Number(hqFloat)) < Number(stopLosstEdge.value)) {
                            stopLoss.value   = stopLosstEdge.value;
                            return;
                        };
                    }
                stopLoss.value  = printFn(Number(stopLoss.value ) - Number(hqFloat));
                break;
            }
         }
         //加
         function add(e){
             switch (e){
                 case 'listProfit': 
                    if(listProfit.value == '' || listProfit.value == '--'){
                        listProfit.value = hqFloat;
                    }else{
                        if(Number(listProfit.value <0 ))return;
                        listProfit.value = printFn(Number(listProfit.value) + Number(hqFloat));
                    }
                break;
                case 'stopProfit': 
                     if(stopProfit.value == '' || stopProfit.value == '--'){
                        stopProfit.value = stopProfitEdge.value
                    }
                    if(props.activeItem.bsType == '2'){
                         if( printFn(Number(stopProfit.value) + Number(hqFloat)) > Number(stopProfitEdge.value)) {
                            stopProfit.value  = stopProfitEdge.value;
                            return;
                        };
                    }
                    stopProfit.value = printFn(Number(stopProfit.value) + Number(hqFloat)); 
                break;
                case 'stopLoss': 
                   if(stopLoss.value == '' || stopLoss.value == '--'){
                        stopLoss.value = stopLosstEdge.value;
                    }
                    if(props.activeItem.bsType == '1'){
                        if( printFn(Number(stopLoss.value) + Number(hqFloat)) > Number(stopLosstEdge.value)) {
                            stopLoss.value  = stopLosstEdge.value;
                            return;
                        };
                    }
                    stopLoss.value = printFn(Number(stopLoss.value) + Number(hqFloat));
                break;
            }
         }
         //指定保留小数位
        function printFn(value) {
            const v  = Number(value).toFixed(props.activeItem.decimalPlace);
            return v;
        }
       function setFulltime(time){
            return time.getTime() + 86400000 < Date.now();
        }
         return {
            stopProfitEdge,
            estimatedProfit,
            stopLosstEdge,
            estimatedLoss,
            stopProfitInput,
            cancelFun,
            listPriceInput,
            confirm,
            add,
            reduce,
            visibleFlag,
            spinning,
            listPriceMax,
            listPriceMin,
            stopLoss,
            stopProfit,
            listProfit,
            stopLossInput,
            loading,
            time,
            setFulltime
         }
     }
};

</script>
<style lang="less">
.ModifyOrder{
    .head{
        overflow: hidden;
        .lable{
            float: left;
            width: 32px;
            text-align: center;
            background: red;
            color: @white;
            font-size: 12px;
            border-radius: 2px;
            margin-top: 2px;
        }
        .symbol{
            float: left;
            font-weight: bold;
            margin-left: 8px;
            font-size: 14px;
            span{
                font-size: 12px;
                color: @text-color-third;
            }
        }
        .hanl_count{
            font-weight: bold;
            float: left;
            margin-left: 52px;
        }
        .prich{
            font-weight: bold;
            float: right;
        }
    }
  .business_form_item{
            display: flex;
            justify-content: space-between;
            padding: 4px 0;/*no*/
            line-height: 40px;/*no*/
            .business_form_item_lable{
                font-size: 14px;/*no*/
                color: @text-color-secondary;
            }
            .business_form_item_value{
                font-size: 14px;/*no*/
                width:250px;/*no*/
                input{
                    text-align: left;
                    font-weight: bold;
                }
                .fuhao{
                    width: 26px;/*no*/
                    color: @primary-color;
                }
                .jian{
                    border-left: 1px solid @border-color-base;/*no*/
                }
                .hr{
                    width: 1px;/*no*/
                    height: 14px;/*no*/
                    margin-top: 9px;/*no*/
                    background:  @border-color-base;
                }
                .business_form_item_value_bottom{
                    color: @text-color-third;
                    font-size: 10px;/*no*/
                    line-height: 14px;/*no*/
                    text-align: left;
                    margin-top: 4px;/*no*/
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                }
                .lable{
                    width: 24px;/*no*/
                    display: inline-block;
                    position: relative;
                    top: 2px;/*no*/
                    display: none;
                }
            }
        }
}
</style>

