<template>
<div class="transaction_symbol">
    <div class="transaction_symbol_search">
        <el-select
            filterable
            v-model="symbol"
            :placeholder="$t('trade.text1')"
            style="width: 100%"
            @change="changeSymbol"
        >
            <el-option v-for="item in symbolSearchArr" :key="item.contractId"  :value="item.symbol" >
            {{item.symbol}}{{item.contractName?'/'+item.contractName:''}}
            </el-option>
        </el-select>
    </div>
    <div class="symbol_type_arr">
        <ul id="symbol_type_arr_id">
            <!-- <li :class="['symbol_type_arr_li','cursor',nowTypeid == ''?'active_type':'']" @click="getWatchListBtn">自选</li>
            <li :class="['symbol_type_arr_li','cursor',nowTypeid == item.contractTypeId?'active_type':'']" @click="getSymbolDataByType(item)" v-for="item in symbolTypeArr" :key="item.contractTypeId">{{item.contractTypeName}}</li>
            <li class="fill" v-for="(item,index) in fillNum" :key="index"></li> -->
        </ul>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" :stretch="false">
            <el-tab-pane :label="$t('trade.text2')" :name="$t('trade.text2')"></el-tab-pane>
            <el-tab-pane :label="item.contractTypeName" :name="item.contractTypeId" v-for="item in symbolTypeArr" :key="item.contractTypeId"></el-tab-pane>
        </el-tabs>
    </div>
    <div class="symbol_arr">
        <div class="symbol_arr_title">
            <div class="symbol_arr_title_type">{{$t('trade.text3')}}</div>
            <div class="symbol_arr_title_price">{{titleFlag == true?$t('trade.text4'):$t('trade.text6')}}</div>
            <div class="symbol_arr_title_spread" v-if="titleFlag">{{$t('trade.text5')}}</div>
            <div class="symbol_arr_title_buy_price" :class="titleFlag == true?'':'symbol_arr_title_change'">{{titleFlag == true?$t('trade.text7'):$t('trade.text8')}}<img @click="changeSortingWay" class="sorting" v-show="!titleFlag" :src="sortWay == 'none'?'https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/sorting.png':sortWay == 'up'?'https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/sorting_u.png':'https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/sorting_d.png'" /> </div>
            <div class="symbol_arr_switch cursor"><i class="iconfont icon-trade_icon_switch" @click="changeTitleFlag"></i></div>
        </div>
        <ul class="symbol_arr_list">
            <div v-if="drawingSymbolArr.length == 0 && !loadingFlag">
                <img src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_no_data%402x.png" alt="">
                <div style="color:#919CAD">{{$t('trade.text66')}}</div>
            </div>
                <!-- <loading-outlined slot="indicator" class="loading" v-if="loadingFlag" style="font-size: 24px;margin-top:48%" spin /> -->
           <li class="cursor"   @click="checkSymbol(item)" v-for="(item,index) in drawingSymbolArr" v-show="!loadingFlag" :key="item.symbol">
               <div class="symbol_arr_item_type" >
                   <div class="symbol_arr_item_type_star">
                        <div class="star cursor" @click.stop="changeWatchList(item)">
                            <img :src="item.isWatch == 1 || nowTypeid==''?'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/praise_stars.png':'https://ehammer.oss-cn-hangzhou.aliyuncs.com/web/trade_icon_optional_gray.png'" alt="">
                        </div>
                        <div class="name">
                            <div class="name_en">{{item.symbol}}</div>
                        </div>
                   </div>
                    <div class="name_zh">{{item.contractName}}</div>
               </div>
               <div class="symbol_arr_item symbol_arr_item_price" :class="item.openStatus == 0? 'graw':oldDrawingSymbolArr.length == 0 ? 'down': item.askPrice - oldDrawingSymbolArr[index].askPrice >= 0 ? 'animaUp':'animaDown'">
                  {{printFn(Number(item.askPrice) + Number(item.askForDiffer),item.decimalPlace)}}
               </div>
               <div v-show="titleFlag" class="symbol_arr_item symbol_arr_item_spread">{{item.spread}}</div>

               <div v-show="titleFlag == true" class="symbol_arr_item symbol_arr_item_bidPrice" :class="item.openStatus == 0? 'graw':oldDrawingSymbolArr.length == 0 ? 'up' : item.bidPrice - oldDrawingSymbolArr[index].bidPrice >= 0 ? 'animaUp':'animaDown'">
                   {{printFn(Number(item.bidPrice) + Number(item.bidForDiffer),item.decimalPlace)}}
               </div>
                <div v-show="titleFlag == false" class="symbol_arr_item  symbol_arr_item_upDownRate" :class="item.openStatus == 0? 'graw':oldDrawingSymbolArr.length != 0 && parseFloat(item.upDownRate) - parseFloat(oldDrawingSymbolArr[index].upDownRate) >= 0 ? 'animaUp':'animaDown'">
                   {{item.upDownRate}}
               </div>
            </li>
            
        </ul>
    </div>
</div>
</template>
<script>
import {
 ref, nextTick, watch, onMounted, onBeforeUnmount,inject,computed
} from 'vue';
import apiServer from '@/api/request';
import { useStore } from 'vuex';
// import { LoadingOutlined } from '@ant-design/icons-vue';
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n";

export default {
    name: 'transymbol',
    components: {
        // LoadingOutlined,
    },
    setup() {
        const emitt = inject('emitt');
        const store = useStore();
        const symbol = ref("");
        const loadingFlag = ref(false);// loading条
        const fillNum = ref(0);// 按钮最后一行应补数
        const symbolTypeArr = ref([]);// 合约类型列表
        const allSymbolArr = ref([]);// 所有合约列表
        const drawingSymbolArr = ref([]);// 渲染合约列表
        const oldDrawingSymbolArr = ref([]);// 上一次渲染合约列表
        const symbolSearchArr = ref([]);// 合约搜索结果列表
        const watchListArr = ref([]);// 自选合约列表
        const nowTypeid = ref('');// 当前展示合约列表分类
        const titleFlag = ref(true);// 品种标题切换
        let timer = null;// 计时器
        const params = {}; // 当前获取合约列表的条件
        const { t } = useI18n()
        const activeName = ref(t('trade.text2'))
        const userToken = computed({ get: () => store.getters.userToken });
        const router = useRouter(); 

        const subscribeTick = () => {
            emitt.$sub('passByValue', (val) => {
                if (drawingSymbolArr.value && drawingSymbolArr.value.length > 0) {
                    const {
                        symbol,
                        askPrice,
                        bidPrice
                    } = val[0]
                    drawingSymbolArr.value = drawingSymbolArr.value.map((item, index) => {
                        if (item.symbol === symbol) {
                            if (oldDrawingSymbolArr.value && oldDrawingSymbolArr.value.length > 0) {
                                oldDrawingSymbolArr.value[index].askPrice = item.askPrice
                                oldDrawingSymbolArr.value[index].bidPrice = item.bidPrice
                            }
                            return {
                                ...item,
                                askPrice,
                                bidPrice
                            }
                        } else {
                            return item
                        }
                    })
          }
        })
        }
        


        onMounted(() => {
            getContractType();// 获取合约分类列表
            getSymbolData();// 获取所有合约列表
            getWatchListBtn();// 进入页面默认点击自选按钮
            subscribeTick()
        });
        onBeforeUnmount(() => {
            clearInterval(timer);
            timer = null;
        });
    

        //计算点差
        function calculateTheSpread(list){
            for(let item of list){
                item.spread = Math.round(Number((Number(item.bidPrice) + Number(item.bidForDiffer)) - (Number(item.askPrice) + Number(item.askForDiffer))).toFixed(item.decimalPlace) * Math.pow(10,Number(item.decimalPlace)))
            }
            return list;
        }

        // 获取商品列表
        function getSymbolData(params = {}) {
            apiServer.product(params).then((res) => {
                if(res.status == 1){
                    if (Object.prototype.hasOwnProperty.call(params, "contractTypeId")) {
                        if (nowTypeid.value == res.data.productList[0].contractTypeId) {
                            oldDrawingSymbolArr.value = drawingSymbolArr.value || []
                            drawingSymbolArr.value = calculateTheSpread(res.data.productList);
                            
                            loadingFlag.value = false;
                            if(!titleFlag.value){
                                Sorting(sortWay.value);
                            }
                        }
                        symbol.value = undefined;
                        symbolSearchArr.value = allSymbolArr.value;
                            // 分类搜索合约
                    } else if (Object.prototype.hasOwnProperty.call(params, "contractId")) {
                        // 搜索框搜索合约
                        nowTypeid.value = res.data.productList[0].contractTypeId;
                        oldDrawingSymbolArr.value = drawingSymbolArr.value || []
                        drawingSymbolArr.value = calculateTheSpread(res.data.productList);
                        if(!titleFlag.value){
                            Sorting(sortWay.value);
                        }
                        loadingFlag.value = false;
                    } else {
                        allSymbolArr.value = res.data.productList;
                        store.commit('PRODUCT_LIST', allSymbolArr.value);
                        symbolSearchArr.value = allSymbolArr.value;
                        loadingFlag.value = false;
                        for(let item of allSymbolArr.value){
                            if(item.symbol == store.state.home.activeSymbolItem.symbol){
                                checkSymbol(item);
                            }
                        }
                    }
                    
                }else{
                     ElMessage.error(res.message);
                }

            });
        }

        // 点击自选分类
        function getWatchListBtn() {
            clearInterval(timer);
            loadingFlag.value = true;
            nowTypeid.value = '';
            timer = null;
            getWatchList();
            timer = setInterval(() => {
                getWatchList();
            }, 3000);
        }
        // 点击合约分类
        function getSymbolDataByType(contractTypeId) {
            if (nowTypeid.value == contractTypeId) return;
            loadingFlag.value = true;
            clearInterval(timer);
            timer = null;
            drawingSymbolArr.value = [];
            nowTypeid.value = contractTypeId;
            delete params.contractId;
            params.contractTypeId = contractTypeId;
            getSymbolData(params);
            timer = setInterval(() => {
                getSymbolData(params);
            }, 3000);
        }
        // 点击搜索结果
        function getSymbolDataBySearch(e) {
            activeName.value = '';
            loadingFlag.value = true;
            timer = null;
            clearInterval(timer);
            drawingSymbolArr.value = [];
            delete params.contractTypeId;
            params.contractId = e;
            getSymbolData(params);
            timer = setInterval(() => {
                getSymbolData(params);
            }, 3000);
        }
         // 获取用户自选商品列表
        function getWatchList() {
            apiServer.watchList().then((res) => {
                if(res.data.isPopup == 0){
                    watchListArr.value = res.data.watchList;
                }else{
                    watchListArr.value = [];
                }
                if (nowTypeid.value == '') {
                    oldDrawingSymbolArr.value = drawingSymbolArr.value || []
                    drawingSymbolArr.value = calculateTheSpread(watchListArr.value);
                    if(!titleFlag.value){
                        Sorting(sortWay.value);
                    }
                    loadingFlag.value = false;
                }
            });
        }
          // 获取商品列表分类
        function getContractType() {
            apiServer.contractType().then((res) => {
                symbolTypeArr.value = res.data.contractTypeList;
            });
        }

        // 输入框筛选条件
        function changeSymbol(value) {
            clearInterval(timer);
            loadingFlag.value = true;
            symbol.value = value;
            console.log('value',value)
            fetch(value, (data) => {
                    oldDrawingSymbolArr.value = drawingSymbolArr.value || []
                    drawingSymbolArr.value = calculateTheSpread(data);
                    if(!titleFlag.value){
                        Sorting(sortWay.value);
                    }
                   getSymbolDataBySearch(data[0].contractId);
                });
        }
         //指定保留小数位
        function printFn(value,decimalPlace) {
            const v  = Number(value).toFixed(decimalPlace);
            return v;
        }
        // 自选添加或删除
        function changeWatchList(item) {
            if(!userToken.value){
                router.push('/signUp');
                return
            }
            const p = {
                    symbol: item.symbol,
                };
            if (nowTypeid.value == '' || item.isWatch == 1) {
                apiServer.removeWatchList(p).then((res) => {
                    if (res.status == 1) {
                         ElMessage({
                            message: t('trade.text9'),
                            type: 'success',
                        })
                        if (item.star == true) {
                           getWatchList();
                        }
                    }
                });
            } else {
               apiServer.addWatchList(p).then((res) => {
                    if (res.status == 1) {
                        ElMessage({
                            message: t('trade.text10'),
                            type: 'success',
                        })
                        getWatchList();
                    }
                });
            }
        }
        // 选择合约
        function checkSymbol(e) {
            store.commit('ACTIVESYMBOL', e);
            emitt.$pub("activeSymbolChange");
            emitt.$pub("activeSymbolChange2");
        }
        function fetch(value, callback) {
            const list = allSymbolArr.value;
            const searchList = [];
            value = value.toUpperCase();
            for (const item of list) {
                if (item.contractName.indexOf(value) != -1 || item.symbol.indexOf(value) != -1) {
                   searchList.push(item);
                }
            }
            callback(searchList);
        }

        //排序
        const sortWay = ref('none');
        function Sorting(way){
            if(way == 'none')return;
            let list = drawingSymbolArr.value;
            let list2 = oldDrawingSymbolArr.value;
            list = SortingFunc(list)
            list2 = SortingFunc(list2)
             if(way == 'up'){
                list.reverse();
                list2.reverse();
            }
            drawingSymbolArr.value = list;
            oldDrawingSymbolArr.value = list2;
        }

        function SortingFunc(list){
            for(let i=0; i<list.length-1; i++){
                for(let j=0; j<list.length-i-1; j++) {
                    if (toPoint(list[j].upDownRate) > toPoint(list[j+1].upDownRate)) {
                            let temp = list[j]
                            list[j] = list[j+1]
                            list[j+1] = temp
                        } 
                    }
            }   
            return list
        }

        function toPoint(percent){
   		 	var str=percent.replace("%","");
   		 	return Number(str)
		}

        //切换排序方式
        function changeSortingWay(){
            if(sortWay.value == 'none' || sortWay.value == 'down'){
                sortWay.value = 'up';
            }else if(sortWay.value == 'up'){
                sortWay.value = 'down'
            }
            Sorting(sortWay.value)
        }
        //切换种类
        function handleClick(e){
            drawingSymbolArr.value = [];
            let contractTypeId = e.props.name
            if(contractTypeId == t('trade.text2')){
                getWatchListBtn();
            }else{
                getSymbolDataByType(contractTypeId);
            }
        }
        

        //切换展示数据
        function changeTitleFlag(){
            titleFlag.value = !titleFlag.value;
            sortWay.value = 'none'
            Sorting(sortWay.value)
        }
        return {
            symbol,
            changeSymbol,
            symbolTypeArr,
            fillNum,
            symbolSearchArr,
            drawingSymbolArr,
            getWatchListBtn,
            getSymbolDataByType,
            nowTypeid,
            titleFlag,
            loadingFlag,
            changeWatchList,
            checkSymbol,
            printFn,
            activeName,
            Sorting,
            handleClick,
            sortWay,
            changeSortingWay,
            changeTitleFlag,
            oldDrawingSymbolArr
        };
    },
};
</script>
<style lang="less" scoped>
.transaction_symbol{
    padding: 8px 0;//5.263157%
    .transaction_symbol_search{
        text-align: left;
        position: relative;
        padding: 0 5.263157%;
        }
        .symbol_type_arr{
            margin-top: 8px;
            padding: 0;
            
        ul{
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            padding-top: 1px;
            .symbol_type_arr_li{
                width: 56px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 12px;
                color: @text-color-secondary;
                background: @content-bg-color;
                margin-top: 8px;
            }
            .active_type{
                background: @primary-color;
                color: @white;
            }
            .symbol_type_arr_li:hover{
                background: @hover-color;
                color:  @text-color-secondary;
            }
            .fill{
                margin-top: 8px;
                width: 56px;
                height: 24px;
            }
        }
    }
    .symbol_arr{
        overflow: hidden;
        .symbol_arr_title{
            padding: 0 10px;
            color: @text-color-third;
            font-size: 12px;
            display: flex;
            justify-content: left;
            text-align: left;
            position: relative;
            .symbol_arr_title_type{
                 width: 99px;/*no*/
            }
            .symbol_arr_title_price,.symbol_arr_title_buy_price{
                width: 64px;/*no*/
                text-align: right;
                img{
                    width: 8px;/*no*/
                    margin-left: 2px;
                    position: relative;
                    top: 1px;
                    cursor: pointer;
                }
            }
            .symbol_arr_title_buy_price{
                width: 46px;/*no*/
            }
            .symbol_arr_title_change{
                width: 103px;/*no*/
            }
            .symbol_arr_title_change{


            }
            .symbol_arr_title_spread{
                width: 66px;/*no*/
                text-align: right;
            }
            .symbol_arr_switch{
                font-size: 12px;/*no*/
                display: inline-block;
                transform: scale(0.6);
                position: absolute;
                right: 2px;/*no*/
                top: 1px;/*no*/
            }
        }
        .symbol_arr_list{
            height: 462px;/*no*/
            overflow-y: scroll;
            .loading{
                color: @primary-color;
            }
            li{
                margin: 5px 0;/*no*/
                padding: 0 10px;/*no*/
                min-height: 32px;/*no*/
                overflow: hidden;
                .symbol_arr_item_type{
                   
                    width: 99px;/*no*/
                    float: left;
                    .symbol_arr_item_type_star{
                        display: flex;
                    }
                    .star{
                        margin-right: 8px;/*no*/
                        position: relative;
                        top: 8px;/*no*/
                        img{
                            display: block;
                            width: 12px;/*no*/
                            height: 12px;/*no*/
                        }
                    }
                    .name{
                        font-size: 12px;/*no*/
                        text-align: left;
                        line-height: 32px;/*no*/
                    }
                    .name_en{
                        color: @text-color;
                    }
                    .name_zh{
                        font-size: 12px;/*no*/
                        color: @description-color;
                        text-align: left;
                        padding-left: 19px;/*no*/
                    }
                }
                .symbol_arr_item{
                    width: 64px;/*no*/
                    line-height: 32px;/*no*/
                    text-align: right;
                    font-size: 12px;/*no*/
                    float: left;
                }
                .symbol_arr_item_price{
                    float: left;
                    width: 64px;/*no*/
                }
                .symbol_arr_item_spread{
                    width: 52px;/*no*/
                    float: left;

                }
                .symbol_arr_item_bidPrice{
                    width: 70px;/*no*/
                }
                .symbol_arr_item_upDownRate{
                    width: 103px;/*no*/
                }
            }
            li:hover{
                background: @bg-color;
            }
                

        }
        // .symbol_arr_list::-webkit-scrollbar {display:none};
    }
}
.animaUp{
    color: @red;
    animation: twinkling 0.5s;
}
.animaDown{
    color: @green;
    animation: twinkling2 0.5s ;
}
@-webkit-keyframes twinkling{ 
        0%{ 
            opacity: 0.3; 
        } 
        100%{ 
            opacity: 1; 
        } 
}
@-webkit-keyframes twinkling2{ 
        0%{ 
            opacity: 0.3; 
        } 
        100%{ 
            opacity: 1; 
        } 
}
</style>
<style lang="less">
.transaction_symbol{
    .el-tabs__item{
        margin-right:16px;
        padding:0 !important;
        height: 24px !important;
        line-height:24px !important;
        font-size:12px !important;
    }
    .el-tabs__nav{
        margin-left:0;
    }
    .el-tabs__nav-next, .el-tabs__nav-prev{
        line-height:29px !important;
    }
    .el-tabs__nav-prev{
        left:5px;/*no*/
    }
    .el-tabs__nav-next{
        right: 5px;/*no*/
    }
    .el-input__inner{
        color:@black;
    }
    .el-tabs__header{
        margin-bottom:8px;/*no*/
    }
    .el-tabs__nav-wrap{
    }
    .el-tabs__item{
        height: 32px !important;/*no*/

    }
}
</style>
