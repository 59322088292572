<template>
  <div class="position_data">
    <div class="order_list_title">
      <div class="position_table">
        <el-table
          v-loading="tloading"
          :data="dataPosition"
          size="small"
          :empty-text="$t('trade.text66')"
        >
          <el-table-column
            fixed="left"
            prop="symbol"
            :label="$t('trade.text50')"
          />
          <el-table-column prop="bsType" width="70" :label="$t('trade.text51')">
            <template #default="scope">
              <span :class="scope.row.bsType == 1 ? 'up' : 'down'">{{
                scope.row.bsType == 1 ? $t("trade.text30") : $t("trade.text32")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            width="50"
            :label="$t('trade.text52')"
          />
          <el-table-column
            prop="orderPrice"
            min-width="115"
            :label="$t('trade.text87')"
          />
          <el-table-column prop="marketPrice" :label="$t('trade.text54')" />
          <el-table-column
            prop="margin"
            min-width="105"
            :label="$t('trade.text88')"
          />
          <el-table-column
            prop="stopProfit"
            min-width="100"
            :label="$t('trade.text58')"
          />
          <el-table-column
            prop="stopLoss"
            :label="$t('trade.text59')"
            min-width="110"
          />
          <el-table-column
            prop="orderNo"
            :label="$t('trade.text61')"
            min-width="115"
          />
          <el-table-column
            prop="orderTime"
            min-width="110"
            :label="$t('trade.text89')"
          />
          <el-table-column
            prop="cancelTime"
            :label="$t('trade.text90')"
            min-width="110"
          />
          <el-table-column :label="$t('trade.text62')" width="210px">
            <template #default="scope">
              <span>
                <el-button link @click="showModifyOrder(scope.row)">{{
                  $t("trade.text91")
                }}</el-button>
                <el-button link @click="cancel(scope.row)">{{
                  $t("trade.text92")
                }}</el-button>
              </span>
            </template>
          </el-table-column>
          <template #empty>
            <div class="empty" v-if="userToken">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_no_data%402x.png"
                alt=""
              />
              <p>{{ $t("trade.text66") }}</p>
            </div>
            <div v-else class="no_login">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_login%402x.png"
                alt=""
              />
              <el-button @click="goLogin" class="login_btn" type="primary">{{
                $t("trade.text67")
              }}</el-button>
            </div>
          </template>
        </el-table>
      </div>
    </div>
    <modify-order
      :marketPrice="activeItem.marketPrice"
      :activeItem="activeItem"
    ></modify-order>
  </div>
</template>
<script>
import { ref, reactive, getCurrentInstance, computed, inject } from "vue";
import apiServer from "@/api/request";
import { useStore } from "vuex";
import { ElMessageBox, ElMessage } from "element-plus";
import ModifyOrder from "./ModifyOrder";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "orderList",
  components: {
    ModifyOrder,
  },
  props: {
    dataPosition: {
      type: Array,
      default: [],
    },
    tloading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { ctx } = getCurrentInstance();
    const { t } = useI18n();
    const emitt = inject("emitt");
    const store = useStore();
    const findSymbol = ref("全部"); //查询合约
    const nowMainColor = computed({ get: () => store.state.home.mainColor });
    const productList = computed({
      get: () => {
        let arr = [{ symbol: "全部" }].concat(store.state.trade.productList);
        return arr;
      },
    });
    const router = useRouter();

    const beginDate = ref("");
    const endDate = ref("");
    const locale = reactive({
      emptyText: " ",
    });
    const nowIndex = ref(0);
    const userToken = computed({ get: () => store.getters.userToken });
    const activeItem = computed({
      get: () => {
        if (nowIndex.value + 1 <= props.dataPosition.length) {
          return props.dataPosition[nowIndex.value];
        } else {
          return {};
        }
      },
    });
    //修改订单
    function showModifyOrder(item) {
      console.log(props.dataPosition, "props.dataPosition");
      for (let i in props.dataPosition) {
        if (props.dataPosition[i].orderNo == item.orderNo) {
          nowIndex.value = Number(i);
          break;
        }
      }
      emitt.$pub("showModifyOrder");
    }
    function cancel(e) {
      if (localStorage.instantTrading == 1) {
        let params = {
          usertoken: localStorage.userToken,
          orderno: e.orderNo,
        };
        apiServer.cancel(params).then((res) => {
          if (res.status == 1) {
            ElMessage({
              message: res.message,
              type: "success",
            });
          }
        });
      } else {
        ElMessageBox.confirm(t("trade.text107"), {
          confirmButtonText: t("trade.text73"),
          cancelButtonText: t("trade.text72"),
        })
          .then(() => {
            let params = {
              usertoken: localStorage.userToken,
              orderno: e.orderNo,
            };
            apiServer.cancel(params).then((res) => {
              if (res.status == 1) {
                ElMessage({
                  message: res.message,
                  type: "success",
                });
              }
            });
          })
          .catch(() => {});
      }
    }
    //查询
    function search() {
      let val = {
        beginDate:
          beginDate.value == "" ? "" : beginDate.value.format("YYYY-MM-DD"),
        endDate: endDate.value == "" ? "" : endDate.value.format("YYYY-MM-DD"),
        findSymbol: findSymbol.value == "全部" ? "" : findSymbol.value,
      };
      emitt.$pub("searchPosition", val);
    }
    function disabledEndDate(endValue) {
      const startValue = beginDate.value;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    }
    function disabledStartDate(startValue) {
      const endValue = endDate.value;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    }
    function goLogin() {
      router.push("/signUp");
    }
    return {
      goLogin,
      findSymbol,
      productList,
      beginDate,
      endDate,
      locale,
      disabledStartDate,
      disabledEndDate,
      search,
      activeItem,
      showModifyOrder,
      cancel,
      userToken,
    };
  },
};
</script>
<style lang="less">
.position_data {
  .order_list_title {
    .ant-tabs-nav-container {
      padding: 0 16px;
    }
    .position {
      color: @text-color-secondary;
      .position_symbol {
        margin-right: 88px;
        .find_title {
          margin-right: 24px;
        }
      }
      .position_date {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        .date_title {
          margin-right: 24px;
        }
        .screening_picker {
          margin: 0 10px;
        }
      }
    }
  }
  .no_login {
    margin-top: 100px;
    margin-bottom: 50px;
    img {
      display: block;
      margin: 0 auto;
    }
    .login_btn {
      margin-top: 24px;
      width: 168px;
    }
  }
  .empty {
    margin-top: 100px; /*no*/
  }

  .el-table--small {
    font-size: 12px; /*no*/
  }
  .el-table::before {
    height: 0;
  }
}
</style>