<template>
  <div class="stopProfitAndStopLoss stopProfitAndStopLoss_modal">
     <el-dialog v-model="visibleFlag" width='480px' :title="$t('trade.text133')" destroy-on-close @close="beforeClose">
             <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text3')}}:</div>
                <div class="business_form_item_value">
                      {{activeItem.symbol}}
                      <img class="lable lable_red" :src="activeItem.bsType == '1'?$t('trade.text94'):$t('trade.text95')" />
                      <img class="lable lable_green" :src="activeItem.bsType == '1'?$t('trade.text94_green'):$t('trade.text95_green')" />
                      <img class="lable lable_blue" :src="activeItem.bsType == '1'?$t('trade.text94_blue'):$t('trade.text95_blue')" />
                </div>
            </div>
             <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text59')}}:</div>
                <div class="business_form_item_value">
                    <div>
                        <el-input @input="stopLossInput"  v-model="stopLoss">
                        <template #suffix>
                            <div class="fuhao jian cursor" @click="reduce('stopLoss')">-</div>
                            <div class="hr"></div>
                            <div  class="fuhao cursor" @click="add('stopLoss')">+</div>
                        </template>
                        </el-input>
                    </div>
                    <div class="business_form_item_value_bottom">
                        <div>{{$t('trade.text96')}}{{activeItem.bsType == '1'?'≤':'≥'}}{{stopLosstEdge}}</div>
                        <div v-show="estimatedLoss">{{$t('trade.text99')}} ${{estimatedLoss}}</div>
                    </div>
                </div>
            </div>
            <div class="business_form_item">
                <div class="business_form_item_lable">{{$t('trade.text58')}}:</div>
                <div class="business_form_item_value">
                        <div>
                            <el-input @input="stopProfitInput"  v-model="stopProfit">
                                <template #suffix>
                                    <div class="fuhao jian cursor" @click="reduce('stopProfit')">-</div>
                                    <div class="hr"></div>
                                    <div  class="fuhao cursor" @click="add('stopProfit')">+</div>
                                </template>
                            </el-input>
                        </div>
                        <div class="business_form_item_value_bottom">
                            <div>{{$t('trade.text96')}}{{activeItem.bsType == '1'?'≥':'≤'}}{{stopProfitEdge}}</div>
                            <div v-show="estimatedProfit">{{$t('trade.text98')}} ${{estimatedProfit}}</div>
                        </div>
                </div>
            </div>
             <template #footer>
                <span class="confirm">
                <el-button @click="visibleFlag = false">{{$t('trade.text72')}}</el-button>
                <el-button type="primary" :loading='loading' @click="confirm"
                    >{{$t('trade.text73')}}</el-button>
                </span>
            </template>
        </el-dialog>
  </div>
</template>

<script>
import {
 ref, reactive, inject, nextTick,getCurrentInstance, computed,watchEffect,onBeforeUnmount
} from 'vue';
import apiServer from '@/api/request';
import { ElMessage } from 'element-plus'
import { useI18n } from "vue-i18n";

export default {
    name:"stopProfitAndStopLoss",
    props:{
        profit:{

        },
        activeItem:{

        }
    },
     setup(props) {
        const {ctx} = getCurrentInstance();
        const { t } = useI18n()
        const emitt = inject('emitt');
        const visibleFlag = ref(false);
        const loading = ref(false);

        const stopProfit = ref("");
        const stopLoss = ref("");
        let hqFloat = '';

        //计算止盈范围
        const stopProfitEdge = computed({ get: () => {
            if (props.activeItem.bsType == '1') {
                    // 如果是买，止盈价格 >= 当前卖价+mindis； 止损价格<= 当前卖价-mindis
                        return printFn(Number(props.activeItem.marketPrice) + Number(minDis));
                }else {
                    // 如果是卖，止盈价格 <= 当前买价-mindis； 止损价格 >= 当前买价+mindis
                        return printFn(Number(props.activeItem.marketPrice) - Number(minDis));
                }
        } });
        //计算止损范围
        const stopLosstEdge = computed({ get: () => {
            if (props.activeItem.bsType == '1') {
                // 如果是买，止盈价格 >= 当前卖价+mindis； 止损价格<= 当前卖价-mindis
                    return printFn(Number(props.activeItem.marketPrice) - Number(minDis));
                }else {
                // 如果是卖，止盈价格 <= 当前买价-mindis； 止损价格 >= 当前买价+mindis
                    return printFn(Number(props.activeItem.marketPrice) + Number(minDis));
                }
        } });

        //计算预估盈利
        const  estimatedProfit = computed({ get: () => {
            if(props.activeItem.bsType == '1'){
                    if(stopProfit.value != '' && stopProfit.value != ''){
                        //预计收益=保证金/杠杆比例*（止盈价-买价）/买价  （保留两位小数点
                        return (props.activeItem.margin/marginRate*(Number(stopProfit.value) - Number(props.activeItem.openPrice))/Number(props.activeItem.openPrice)).toFixed(2);  
                    }
                }else{
                    if(stopProfit.value != '' &&  stopProfit.value != '--'){
                        //预计收益=保证金/杠杆比例*（卖价-止盈价）/卖价   （保留两位小数点）
                        return (props.activeItem.margin/marginRate*( Number(props.activeItem.openPrice) - Number(stopProfit.value))/Number(props.activeItem.openPrice)).toFixed(2);
                    }
                }
        } });
        //计算预估亏损
        const  estimatedLoss = computed({ get: () => {
            if(props.activeItem.bsType == '1'){
                    if(stopLoss.value != '' && stopLoss.value != '--'){
                         //可能亏损=保证金/杠杆比例*（止损价-买价）/买价   （保留两位小数点）
                       return (props.activeItem.margin/marginRate*(Number(stopLoss.value) -  Number(props.activeItem.openPrice))/ Number(props.activeItem.openPrice)).toFixed(2);
                    }
                }else{
                    if(stopLoss.value != '' && stopLoss.value != '--'){
                        //可能亏损=保证金/杠杆比例*（卖价-止损价）/卖价     （保留两位小数点）
                       return (props.activeItem.margin/marginRate*( Number(props.activeItem.openPrice) - Number(stopLoss.value))/ Number(props.activeItem.openPrice)).toFixed(2);
                    }
                }
        } });

        //打开弹框
        emitt.$sub('showStopProfitAndStopLoss', (val) => {
            nextTick(() => {
                if(val[0] != false){
                    visibleFlag.value = true;
                    buyPage();
                    loading.value = true;
                }else{
                    visibleFlag.value = false;
                }
               
            })
        });

         onBeforeUnmount(() => {
            emitt.$unsub("showStopProfitAndStopLoss");
          });
        let marginRate = '';//杠杆比例
        let minDis = '';
         function buyPage(){
                const params = {
                    symbol: props.activeItem.symbol,
                };
                apiServer.buyPage(params).then((res) => {
                if (res.status == 1) {
                    marginRate = res.data.marginRate;
                    stopProfit.value = props.activeItem.stopProfit == '--' ? '':props.activeItem.stopProfit;;
                    stopLoss.value = props.activeItem.stopLoss == '--' ? '':props.activeItem.stopLoss;
                    hqFloat = res.data.hqFloat;
                    minDis = res.data.minDis;
                    loading.value = false;
                    let index = hqFloat.toString().indexOf('.')
                    if(hqFloat.toString().length-1 - index > Number(props.activeItem.decimalPlace)){
                        hqFloat = 1 * Math.pow(10,-props.activeItem.decimalPlace)
                    }
                }
            });
        }
       //止盈校验
        function stopProfitInput(v){
             let val = v
           let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
           if(val[val.length-1] != '.'){
                if(!regu.test(val)){
                    val = val.substring(0,val.length-1)
                }
           }else{
               if(val.indexOf('.') != val.lastIndexOf('.')){
                   val = val.substring(0,val.length-1)
               }
           }
            
            if(val.indexOf('.') != -1){
                 if(val.toString().split(".")[1].length>props.activeItem.decimalPlace){
                    val = Number(val).toFixed(props.activeItem.decimalPlace)
                }
            }
            nextTick(() => {
                stopProfit.value = val;
            })
        }
        //止损校验
        function stopLossInput(v){
            let val = v
            let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
            if(val[val.length-1] != '.'){
                    if(!regu.test(val)){
                        val = val.substring(0,val.length-1)
                    }
            }else{
                if(val.indexOf('.') != val.lastIndexOf('.')){
                    val = val.substring(0,val.length-1)
                }
            }
                
                if(val.indexOf('.') != -1){
                    if(val.toString().split(".")[1].length>props.activeItem.decimalPlace){
                        val = Number(val).toFixed(props.activeItem.decimalPlace)
                    }
                }
                nextTick(() => {
                    stopLoss.value = val;
                })
        }
        function cancelFun(){
            console.log("cancelFun")
        }
        //确认
         function confirm(){
              if (props.activeItem.bsType == '1') {
                    // 如果是买
                    // Price{{activeItem.bsType == '1'?'≤':'≥'}}{{stopLosstEdge}}
                    if(stopLoss.value != '' && Number(stopLoss.value) > Number(stopLosstEdge.value)){
                        ElMessage.error(`${t('trade.text102')} <= `+stopLosstEdge.value);
                        return 
                    }
                    if(stopProfit.value != '' && Number(stopProfit.value) < Number(stopProfitEdge.value)){
                        ElMessage.error(`${t('trade.text103')} >= `+stopProfitEdge.value);
                        return 
                    }
                    
                }else {
                    // 如果是卖
                    if(stopLoss.value != '' && Number(stopLoss.value) < Number(stopLosstEdge.value)){
                        ElMessage.error(`${t('trade.text102')} >= `+stopLosstEdge.value);
                        return 
                    }
                    if(stopProfit.value != '' && Number(stopProfit.value) > Number(stopProfitEdge.value)){
                        ElMessage.error(`${t('trade.text103')} <= ` + stopProfitEdge.value);
                        return 
                    }
                }
            

             let params = {
                 usertoken:localStorage.userToken,
                 posid:props.activeItem.posId,
                 stopprofit:stopProfit.value == '' ? '--':stopProfit.value,
                 stoploss:stopLoss.value == '' ? '--':stopLoss.value
             }
             loading.value = true;
             apiServer.setStopPrice(params).then(res => {
                 if(res.status == 1){
                     visibleFlag.value = false;
                     ElMessage({
                            message: t('trade.text86'),
                            type: 'success',
                        });
                     cancelFun();
                 }else{
                      ElMessage({
                        type: 'error',
                        message: res.message
                    });
                 }
                 loading.value = false;
             })
         }
         //减
         function reduce(e){
              switch (e){
                case 'stopProfit': 
                     if(stopProfit.value == '' || stopProfit.value == '--'){
                        stopProfit.value = stopProfitEdge.value
                    }
                    if(props.activeItem.bsType == '1'){
                         if( printFn(Number(stopProfit.value) - Number(hqFloat)) < Number(stopProfitEdge.value)) {
                            stopProfit.value  = stopProfitEdge.value;
                            return;
                        };
                    }else{
                        if( printFn(Number(stopProfit.value) - Number(hqFloat)) < 0)return;
                    }
                    stopProfit.value = printFn(Number(stopProfit.value) - Number(hqFloat));
                break;
                case 'stopLoss': 
                    if(stopLoss.value  == '' || stopLoss.value  == '--'){
                        stopLoss.value  = stopLosstEdge.value;
                    }
                    if(props.activeItem.bsType == '1'){
                        if( printFn(Number(stopLoss.value ) - Number(hqFloat)) <=0) return;
                    }else{
                         if( printFn(Number(stopLoss.value ) - Number(hqFloat)) < Number(stopLosstEdge.value)) {
                            stopLoss.value   = stopLosstEdge.value;
                            return;
                        };
                    }
                        stopLoss.value  = printFn(Number(stopLoss.value ) - Number(hqFloat));
                break;
            }
         }
         //加
         function add(e){
             switch (e){
                case 'stopProfit':
                    if(stopProfit.value == '' || stopProfit.value == '--'){
                        stopProfit.value = stopProfitEdge.value
                    }
                    if(props.activeItem.bsType == '2'){
                         if( printFn(Number(stopProfit.value) + Number(hqFloat)) > Number(stopProfitEdge.value)) {
                            stopProfit.value  = stopProfitEdge.value;
                            return;
                        };
                    }
                    stopProfit.value = printFn(Number(stopProfit.value) + Number(hqFloat)); 
                break;
                case 'stopLoss': 
                if(stopLoss.value == '' || stopLoss.value == '--'){
                        stopLoss.value = stopLosstEdge.value;
                    }
                    if(props.activeItem.bsType == '1'){
                        if( printFn(Number(stopLoss.value) + Number(hqFloat)) > Number(stopLosstEdge.value)) {
                            stopLoss.value  = stopLosstEdge.value;
                            return;
                        };
                    }
                    stopLoss.value = printFn(Number(stopLoss.value) + Number(hqFloat));
                break;
            }
         }
       
        //指定保留小数位
        function printFn(value) {
            const v  = Number(value).toFixed(props.activeItem.decimalPlace);
            return v;
        }

        function beforeClose(){
            stopLoss.value = '';
            stopProfit.value = '';
        }
         return {
             visibleFlag,
             confirm,
             reduce,
             add,
             stopProfitEdge,
             estimatedProfit,
             stopLosstEdge,
             estimatedLoss,
             cancelFun,
             stopProfit,
             stopLoss,
             stopProfitInput,
             stopLossInput,
             loading,
             beforeClose
         }
     }
};

</script>
<style lang="less">
.stopProfitAndStopLoss_modal{
    .business_form_item{
            display: flex;
            justify-content: space-between;
            padding: 4px 0;/*no*/
            line-height: 40px;/*no*/
            .business_form_item_lable{
                font-size: 14px;/*no*/
                color: @text-color-secondary;
            }
            .business_form_item_value{
                font-size: 14px;/*no*/
                width:270px;/*no*/
                input{
                    text-align: left;
                    font-weight: bold;
                }
                .fuhao{
                    width: 26px;/*no*/
                    color: @primary-color;
                }
                .jian{
                    border-left: 1px solid @border-color-base;/*no*/
                }
                .hr{
                    width: 1px;/*no*/
                    height: 14px;/*no*/
                    margin-top: 9px;/*no*/
                    background:  @border-color-base;
                }
                .business_form_item_value_bottom{
                    color: @text-color-third;
                    font-size: 10px;/*no*/
                    line-height: 14px;/*no*/
                    text-align: left;
                    margin-top: 4px;/*no*/
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                }
                .lable{
                    width: 24px;/*no*/
                    display: inline-block;
                    position: relative;
                    top: 2px;/*no*/
                    display: none;
                }
            }
        }
}
</style>
